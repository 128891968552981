import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { relative } from 'path';
import { relativeTimeRounding } from 'moment';
import { Observable } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { MessagePayload } from '@angular/fire/messaging'


@Injectable({
  providedIn: 'root',
})
export class MessagingService {

  constructor(
    private router: Router,
    private readonly route: ActivatedRoute,
    private afMessaging: AngularFireMessaging
  ) {

  }

  private handleMessage(payload: MessagePayload): void {
    console.log('handleMessage called with payload:', payload);
    
    if (localStorage.getItem("sessionToken") && this.isSrmRefreshRequired(payload)) {

        this.refreshCurrentRoute();

    } else {
      if (Notification.permission === 'granted') {
        console.log('Notification permission granted, creating notification');
        this.createNotification(payload);
      } else {
        console.log('Notification permission not granted');
      }
    }
  }

  isSrmRefreshRequired(payload) {
    const isRelevantRoute = this.router.url.includes('chat') || this.router.url.includes('srm');

    const isNewMessageNotification = payload.notification?.title.includes('You have a new message.');

    return isRelevantRoute && isNewMessageNotification;
  }

  loadNotification(): void {
    if ('Notification' in window) {
      // this.messaging = getMessaging();
      this.requestPermission();
      this.listen();
    } else {
      alert("Browser does not support Notifications API.")
    }
  }

  private refreshCurrentRoute(): void {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]).then(() => {
      }).catch(error => {
        console.error('Error navigating back to original URL:', error);
      });
    }).catch(error => {
      console.error('Error navigating to root:', error);
    });
  }

  private createNotification(payload: MessagePayload): void {
    console.log('Creating notification with payload:', payload);
    if (!payload.notification) {
      return;
    }

    const notification = new Notification(
      payload.notification.title || 'Notification Title',
      {
        body: payload.notification.body,
        icon: payload.notification.icon,
      }
    );

    notification.onclick = () => {
      console.log('Notification clicked:', payload);
      if (payload.notification?.title.includes('You have a new message.')) {
        this.router.navigate(["/chat"], { relativeTo: this.route })
      } else if (payload.fcmOptions?.link) {
        window.open(payload.fcmOptions.link);
      }
    };
  }

 

  private sendTokenToServer(token: string): void {
    console.log("Sending token to server:", token);
    // Implement actual send logic here
  }

  // Request permission for notifications (only allowed on user gesture)
  async requestPermission(): Promise<string | null> {
    return new Promise((resolve, reject) => {
      this.afMessaging.requestPermission.subscribe({
        next: (permission) => {
          console.log('Permission granted! Save to the server!', permission);
  
          const maxRetries = 3;
          let retries = 0;
  
          const attemptGetToken = () => {
            this.getToken().subscribe({
              next: (token: string | undefined) => {
                if (token) {
                  resolve(token);
                } else {
                  reject('Token is null');
                }
              },
              error: (err: any) => {
                console.error('Error getting FCM token', err);
                if (retries < maxRetries) {
                  retries++;
                  console.log(`Retrying to get token... Attempt ${retries}`);
                  attemptGetToken();
                } else {
                  reject(err);
                }
              }
            });
          };
  
          attemptGetToken();
        },
        error: (err) => {
          console.error('Unable to get permission to notify.', err);
          reject(err);
        }
      });
    });
  }


  private getToken(): Observable<string> {
    return new Observable((observer) => {
      this.afMessaging.requestToken.subscribe({
        next: (token: any) => {
          console.log('FCM Token:', token);
          observer.next(token);
          observer.complete();
        },
        error: (err) => {
          console.error('Permission denied:', err);
          // navigator.serviceWorker.getRegistrations().then(registrations => {
          //   for (var i = 0; i < registrations.length; i++) {
          //     console.log(registrations[i].active?.scriptURL, registrations[i].active?.state);
          //   }
          // });
          observer.error(err);
        }
      });
    });
  }

  listen(): void {
    this.afMessaging.messages.subscribe((message: MessagePayload) => {
      console.log('Message received:', message);
      this.handleMessage(message);
    });
  }

  isPermissionGranted(): boolean {
    return   ('Notification' in window)?Notification.permission === 'granted':false;
  }

   checkNotificationStatus() {
    let message = ""; // Initialize the message to display
    let showNotificationDialog = true; // Flag to show the notification dialog
    let showAllow = true; // Flag to show the 'Allow' button
    let token = null; // Placeholder for the FCM token
    if(('Notification' in window)){
      try {
        // Get the current notification permission status
        const permissionStatus = Notification.permission;
    
        switch (permissionStatus) {
          case "default": // Permission is 'ask'
            message = "We would like to send you notifications to keep you updated.<br/> Please allow notifications.";
            break;
    
          case "denied": // Permission is blocked
            message = "Notifications are blocked.<br/> Please enable them in your browser settings to stay updated.";
            showAllow = false;
            break;
    
          case "granted": // Permission is granted
            // Attempt to get the FCM token
              message = "Notifications are enabled.<br/> You’ll now receive updates.";
              showNotificationDialog = false;
              showAllow = false;
            break;
    
          default:
            message = "Unexpected notification status.";
        }
      } catch (error) {
        console.error("Error checking notification status or getting token:", error);
        message = "An error occurred while checking notification status.<br/> Please try again later.";
      }
    }else{
      message = "Your browser doesn’t support notifications.<br/> Please use a modern browser to enable this feature.";
      showAllow=false;
    }
   
  
    // Display the message
    console.log(message , showNotificationDialog);
  
    return {"message":message,"showNotificationDialog":showNotificationDialog,"showAllow":showAllow};
  }
// Request permission for notifications (only allowed on user gesture)
  async requestNotificationPermission() {
    try {
      const token = await this.requestPermission();
     

      if (!localStorage.getItem('deviceToken') || localStorage.getItem('deviceToken') !== token) {
        localStorage.setItem('deviceToken', token);
        console.log('Token saved to local storage');
      } else {
        console.log('Token already saved in local storage');
      }

      this.listen();

    } catch (error) {
      console.error('Error getting FCM token', error);
    }
  }
}
